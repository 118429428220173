import request from "@/utils/request"

// 查询订单管理列表
export function orderList(params) {
  return request({
    url: "/server/order/list",
    method: "get",
    params: params,
  })
}

// 订单支付
export function orderPay(data) {
  return request({
    url: "/server/order/pay",
    method: "post",
    data: data,
  })
}
// 预约码订单关联
export function needQrCodeOrder(data) {
  return request({
    url: "/server/order/needQrCodeOrder",
    method: "post",
    data: data,
  })
}
// 查询待支付订单详情
export function listPay(params) {
  return request({
    url: "/server/order/listPay",
    method: "get",
    params: params,
  })
}

// 获取订单详情
export function getOrderDetail(appId) {
  return request({
    url: `/server/order/getInfo/${appId}`,
    method: "get",
  })
}

// 修改订单收货信息
export function editAddr(data) {
  return request({
    url: "/server/order/editAddr",
    method: "post",
    data: data,
  })
}

// 同步订单
export function syncOrder(params) {
  return request({
    url: "/server/order/sync",
    method: "get",
    params: params,
  })
}

// 修改产品订单sku
export function editSku(data) {
  return request({
    url: "/server/order/editSku",
    method: "post",
    data: data,
  })
}

// 拆分产品订单sku
export function splitSku(data) {
  return request({
    url: "/server/order/splitSku",
    method: "post",
    data: data,
  })
}

// 关联产品 订单sku
export function matchSku(data) {
  return request({
    url: "/server/order/matchSku",
    method: "post",
    data: data,
  })
}

// 批量关联产品 订单sku
export function matchSkuPics(data) {
  return request({
    url: "/server/order/matchSkuPics",
    method: "post",
    data: data,
  })
}

// 批量检测关联产品 订单sku
export function checkMatchSkuPics(data) {
  return request({
    url: "/server/order/checkMatchSkuPics",
    method: "post",
    data: data,
  })
}

// 修改订单销售金额
export function editSale(data) {
  return request({
    url: "/server/order/editSale",
    method: "post",
    data: data,
  })
}

// 查询手续费列表
export function listPlatFee() {
  return request({
    url: "/server/order/listPlatFee",
    method: "get",
  })
}

// 手续费率保存
export function addPlatFee(data) {
  return request({
    url: "/server/order/addPlatFee",
    method: "post",
    data: data,
  })
}

// 查询汇率列表
export function listCurrencyFee() {
  return request({
    url: "/server/order/listCurrencyFee",
    method: "get",
  })
}

// 汇率保存
export function addCurrencyFee(data) {
  return request({
    url: "/server/order/addCurrencyFee",
    method: "post",
    data: data,
  })
}

// 订单复制
export function orderCopy(data) {
  return request({
    url: "/server/order/copy",
    method: "post",
    data: data,
  })
}

// 订单取消
export function cancelOrder(data) {
  return request({
    url: "/server/order/cancel",
    method: "post",
    data: data,
  })
}

// 订单恢复
export function recoveryOrder(data) {
  return request({
    url: "/server/order/recovery",
    method: "post",
    data: data,
  })
}

// 查询已关联产品列表
export function listLink(params) {
  return request({
    url: "/server/order/listLink",
    method: "get",
    params: params,
  })
}

// 删除已关联产品
export function deleteLink(ids) {
  return request({
    url: `/server/order/deleteLink/${ids}`,
    method: "post",
  })
}

// 查询已转换产品列表
export function listChange(params) {
  return request({
    url: "/server/order/listChange",
    method: "get",
    params: params,
  })
}

// 删除已转换产品
export function deleteChange(ids) {
  return request({
    url: `/server/order/deleteChange/${ids}`,
    method: "post",
  })
}

// 查询导入列表
export function listImport(params) {
  return request({
    url: "/server/order/listImport",
    method: "get",
    params: params,
  })
}

// 订单导入
export function uploadUniversalOrder(data) {
  return request({
    url: "/server/order/uploadUniversal",
    method: "post",
    data: data,
  })
}

// 订单导入
export function uploadLazada(data) {
  return request({
    url: "/server/order/uploadLazada",
    method: "post",
    data: data,
  })
}

// 订单导入
export function uploadOrder(data) {
  return request({
    url: "/server/order/upload",
    method: "post",
    data: data,
  })
}

// 订单导入
export function uploadTb(data) {
  return request({
    url: "/server/order/uploadTb",
    method: "post",
    data: data,
  })
}

// 获取亚马逊订单导入详细信息
export function getImportInfo(importId) {
  return request({
    url: `/server/order/getImportInfo/${importId}`,
    method: "get",
  })
}

// 复制订单详情
export function orderToCopy(appId) {
  return request({
    url: `/server/order/toCopy/${appId}`,
    method: "get",
  })
}

// 转换产品 订单sku
export function changeSku(data) {
  return request({
    url: "/server/order/changeSku",
    method: "post",
    data: data,
  })
}

// 删除产品 订单sku
export function deleteSku(appSkuId) {
  return request({
    url: "/server/order/deleteSku/" + appSkuId,
    method: "POST",
  })
}

// 查询FBA订单管理列表
export function listFba(params) {
  return request({
    url: "/server/order/listFba",
    method: "get",
    params: params,
  })
}

// 获取FBA订单详细信息
export function getFbaInfo(appId) {
  return request({
    url: `/server/order/getFbaOr1688Info/${appId}`,
    method: "get",
  })
}

// 订单审核
export function auditSale(data) {
  return request({
    url: "/server/order/audit",
    method: "post",
    data: data,
  })
}

export function loadAllAppIds(form) {
  let shopId =
    form.account == "all"
      ? null
      : form.account == "manual"
      ? null
      : form.account
  let manualFlag = form.account == "all" ? null : form.manualFlag
  let eId = form.channel == "all" ? null : form.channel
  let status = form.status == "all" ? null : form.status
  let country = form.country == "all" ? null : form.country
  let params = {
    eId: eId,
    shopId: shopId,
    sOrderStatus: status,
    country: country,
    cons: form.cons,
    eAppNo: form.eAppNo,
    sAppNo: form.sAppNo,
    sortord: form.sortord,
    byAsc: form.byAsc,
    startLimit: form.startLimit,
    endLimit: form.endLimit,
    manualFlag: manualFlag,
  }
  return request({
    url: "/order/all/appIds",
    method: "get",
    params: params,
  })
}

export function saveOrder(params) {
  return request({
    url: "/order/create",
    method: "put",
    data: params,
  })
}

export function saveRemark(params) {
  return request({
    url: "/order/saveRemark",
    method: "put",
    params: params,
  })
}

export function updateOrderState(params) {
  return request({
    url: "/order/updateState",
    method: "put",
    params: params,
  })
}

export function getNoteList(appId) {
  return request({
    url: "/order/" + appId + "/all/note",
    method: "get",
  })
}

//根据订单号获取拣货说明
export function getOrderJHNote(appId) {
  return request({
    url: "/order/" + appId + "/jh/note",
    method: "get",
  })
}

export function deleteRemark(id) {
  return request({
    url: "/order/remark/" + id + "/delete",
    method: "delete",
  })
}
//将订单添加到黑名单
export function moveToBlack(id) {
  return request({
    url: "/order/" + id + "/moveToBlack",
    method: "put",
  })
}

export function exportExcel(params) {
  return request({
    url: "/order/export",
    method: "get",
    data: params,
  })
}
export function downloadFile(params) {
  return request({
    url: "common/download",
    method: "get",
    params: params,
  })
}

export function getSynAllOrders(params) {
  return request({
    url: "/order/getSynAllOrders",
    method: "get",
    params: params,
  })
}

export function updataExpre(params) {
  return request({
    url: "/order/updateExpre",
    method: "put",
    params: params,
  })
}
// 订单加急
export function createShip(data) {
  return request({
    url: "/server/order/ship/create",
    method: "post",
    data: data,
  })
}
// 结算页面查询支持的物流方式
export function getOrderShip(data) {
  return request({
    url: "/server/order/ship",
    method: "post",
    data: data,
  })
}
// 修改订单物流信息
export function editExpre(data) {
  return request({
    url: "/server/order/editExpre",
    method: "post",
    data: data,
  })
}

// 查询FBA订单管理列表
export function list1688(params) {
  return request({
    url: "/server/order/list1688",
    method: "get",
    params: params,
  })
}

// 查询FBA订单管理列表
export function sendUserCodeSms(params) {
  return request({
    url: "/server/order/sendUserCodeSms",
    method: "post",
    params: params,
  })
}
// 修改转换产品
export function editChange(data) {
  return request({
    url: "/server/order/editChange",
    method: "post",
    data: data,
  })
}

// 关联订单详情
export function editLink(data) {
  return request({
    url: `/server/order/editLink`,
    method: "post",
    data: data,
  })
}
// 关联订单详情
export function sendByHand(data) {
  return request({
    url: `/server/order/sendByHand`,
    method: "post",
    data: data,
  })
}
// 关联订单详情
export function sendPhByHand(data) {
  return request({
    url: `/server/order/sendPhByHand`,
    method: "post",
    data: data,
  })
}
// 物流追踪
export function getExpreProcess(data) {
  return request({
    url: `/sOrderExpreProcess/getExpreProcess`,
    method: "GET",
    params: data,
  })
}

//查询物流进度
export function selectExpreList(data) {
  return request({
    url: `/sOrderExpre/selectExpreList`,
    method: "GET",
    params: data,
  })
}

//查询物流进度
export function editOrder(data) {
  return request({
    url: `/server/order/edit`,
    method: "POST",
    data: data,
  })
}
//退款
export function forceCancel(data) {
  return request({
    url: `/server/order/forceCancel`,
    method: "POST",
    data: data,
  })
}
//退款
export function getDesignInfo(data) {
  return request({
    url: `/server/order/getDesignInfo?designId=` + data,
    method: "GET",
  })
}

// 获取最多可生成的商品编码数量
export function setOrderRemark(data) {
  return request({
    url: "/server/order/setOrderRemark",
    method: "post",
    data: data,
  })
}

// 获取最多可生成的商品编码数量
export function deleteOrderRemark(data) {
  return request({
    url: "/server/order/deleteOrderRemark/" + data,
    method: "post",
  })
}

// 获取最多可生成的商品编码数量
export function getTb(data) {
  return request({
    url: "/server/order/getTb",
    method: "get",
    params: data,
  })
}

// 订单导入
export function uploadShopee(data) {
  return request({
    url: "/server/order/uploadShopee",
    method: "post",
    data: data,
  })
}

// 订单导入
export function shippingParameter(data) {
  return request({
    url: "/server/order/shippingParameter",
    method: "GET",
    params: data,
  })
}
// 订单导入
export function shippingOrder(data) {
  return request({
    url: "/server/order/shippingOrder",
    method: "POST",
    data: data,
  })
}

// 获取最多可生成的商品编码数量
export function downloadDoc(data) {
  return request({
    url: "/server/order/downloadDoc",
    method: "get",
    params: data,
  })
}

export function handSetOrder(data) {
  return request({
    url: "/server/order/handSetOrder",
    method: "POST",
    data: data,
  })
}

export function batchPackOrder(data) {
  return request({
    url: "/server/order/batchPackOrder",
    method: "POST",
    data: data,
    params: data,
  })
}

export function batchGetOrderDoc(data) {
  return request({
    url: "/server/order/batchGetOrderDoc",
    method: "POST",
    data: data,
    params: data,
  })
}

export function batchShippingOrder(data) {
  return request({
    url: "/server/order/batchShippingOrder",
    method: "POST",
    data: data,
    params: data,
  })
}

export function batchDownloadDoc(data) {
  return request({
    url: "/server/order/BatchDownloadDoc",
    method: "GET",
    data: data,
    params: data,
  })
}

export function getOrderCnt(data) {
  return request({
    url: "/server/order/getOrderCnt",
    method: "GET",
    data: data,
    params: data,
  })
}

export function batchSendPhByHand(data) {
  return request({
    url: "/server/order/batchSendPhByHand",
    method: "POST",
    data: data,
  })
}

export function getOrderSellerCnt(data) {
  return request({
    url: "/server/order/getOrderSellerCnt",
    method: "GET",
    params: data,
    data: data
  })
}


export function batchGetTiktokDoc(data) {
  return request({
    url: "/server/order/batchGetTiktokDoc",
    method: "GET",
    data: data,
    params: data,
  })
}
export function batchShipPackage(data) {
  return request({
    url: "/server/order/batchShipPackage",
    method: "POST",
    data: data,
    params: data,
  })
}
export function listCntShopee(data) {
  return request({
    url: "/product/shopeeApi/listCnt",
    method: "GET",
    params: data,
  })
}
export function listCntTiktok(data) {
  return request({
    url: "/product/tiktokApi/listCnt",
    method: "GET",
    params: data,
  })
}
export function getCategories(data) {
  return request({
    url: "/product/tiktokApi/getCategories",
    method: "GET",
    params: data,
  })
}

export function uploadTiktok(data) {
  return request({
    url: "/server/order/uploadTiktok",
    method: "POST",
    data: data,
  })
}

export function moveProduct(data) {
  return request({
    url: "/server/product/moveProduct",
    method: "POST",
    data: data,
  })
}
// 结算页面查询支持的物流方式
export function freshByAppNo(data) {
  return request({
    url: '/server/order/freshByAppNo',
    method: 'get',
    params: data
  })
}
export function sendTemuShip(data) {
  // temu批量发货
  return request({
    url: "/server/order/sendTemuShip",
    method: "POST",
    data: data,
  })
}
export function setOrderFee(data) {
  // 补费
  return request({
    url: "/server/order/setOrderFee",
    method: "POST",
    data: data,
  })
}
export function setFactoryUser(data) {
  // 手动派单
  return request({
    url: "/server/order/setFactoryUser",
    method: "POST",
    data: data,
  })
}
// 自动派单
export function setFactoryUserAuto(params) {
  return request({
    url: "/server/order/setFactoryUserAuto",
    method: "GET",
    params,
  })
}
// 批量备注
export function setAdminRemark(params) {
  return request({
    url: '/server/order/setAdminRemark',
    method: 'get',
    params: params
  })
}
// 自动下载-启动任务
export function zipPicklistPdf(params) {
  return request({
    url: '/server/order/zipPicklistPdf',
    method: 'get',
    params: params
  })
}

// 自动下载-任务列表
export function zipList(params) {
  return request({
    url: '/server/order/zipList',
    method: 'get',
    params: params
  })
}
// 自动下载-新拣货单详情
export function orderReportList(params) {
  return request({
    url: '/server/report/list',
    method: 'get',
    params: params
  })
}
// 自动下载-拣货单设置接单工厂
export function orderReportEdit(data) {
  return request({
    url: '/server/report/edit',
    method: 'post',
    data: data
  })
}

// 自动下载-导出
export function orderReportExport(params) {
  return request({
    url: '/server/report/export',
    method: 'get',
    params: params
  })
}

// 自动下载-删除压缩包
export function delZip(data) {
  return request({
    url: '/server/order/delZip',
    method: 'post',
    data: data
  })
}

// 查询历史条件
export function getTimingData(data) {
  return request({
    url: '/server/order/getTimingData',
    method: 'get',
    params: data
  })
}
// 运营利润报表
export function getProfitList(data) {
  return request({
    url: '/server/profit/list',
    method: 'get',
    params: data
  })
}
// 每日运营利润报表
export function getProfitDayList(data) {
  return request({
    url: '/server/profit/dayList',
    method: 'get',
    params: data
  })
}
// 一键发货
export function setOrderAuto(data) {
  return request({
    url: '/server/order/setOrderAuto',
    method: 'post',
    data: data
  })
}
// 自动关联-关系列表
export function getMatchList(data) {
  return request({
    url: '/server/match/list',
    method: 'get',
    params: data
  })
}
// 自动关联-新增关系
export function matchAdd(data) {
  return request({
    url: '/server/match/add',
    method: 'post',
    data: data
  })
}
// 自动关联-去关联
export function matchSkuDz(data) {
  return request({
    url: '/server/order/matchSkuDz',
    method: 'post',
    data: data
  })
}
// 自动关联-删除
export function matchRemove(data) {
  return request({
    url: '/server/match/remove',
    method: 'post',
    data: data
  })
}
// 删除订单
export function deleteOrder(data) {
  return request({
    url: '/server/order/deleteOrder',
    method: 'post',
    data: data
  })
}
// 导出订单
export function downloadPicklistPdf(data) {
  return request({
    url: '/server/order/downloadPicklistPdf',
    method: 'get',
    params: data
  })
}
// 下载工厂图
export function downloadBoxpic(data) {
  return request({
    url: '/server/order/downloadBoxpic',
    method: 'get',
    params: data
  })
}
// 下载原图
export function downloadOriginalImage(data) {
  return request({
    url: '/server/order/downloadOriginalImage',
    method: 'get',
    params: data
  })
}
// 下载标签
export function downloadBoxlabel(data) {
  return request({
    url: '/server/order/downloadBoxlabel',
    method: 'get',
    params: data
  })
}
// 下载打印图
export function downloadPrintImage(data) {
  return request({
    url: '/server/order/downloadPrintImage',
    method: 'get',
    params: data
  })
}
// 下载拣货单-标记已下载
export function downloadZipFileNew(data) {
  return request({
    url: '/server/order/downloadZipFileNew',
    method: 'get',
    params: data
  })
}

// 新导出订单
export function downloadPicklistPdfNew(data) {
  return request({
    url: '/server/order/downloadPicklistPdfNew',
    method: 'get',
    params: data
  })
}
// 下载打印图
export function downloadPrintImageNew(data) {
  return request({
    url: '/server/order/downloadPrintImageNew',
    method: 'get',
    params: data
  })
}
// 下载工厂图
export function downloadBoxpicNew(data) {
  return request({
    url: '/server/order/downloadBoxpicNew',
    method: 'get',
    params: data
  })
}
// 下载原图
export function downloadOriginalImageNew(data) {
  return request({
    url: '/server/order/downloadOriginalImageNew',
    method: 'get',
    params: data
  })
}
// 下载中心-列表
export function reportList(data) {
  return request({
    url: '/server/report/file/list',
    method: 'get',
    params: data
  })
}
// 下载中心-标记已下载
export function markAsDownloaded(data) {
  return request({
    url: '/server/report/file/markAsDownloaded',
    method: 'get',
    params: data
  })
}
// // 下载中心-删除
export function reportFileDel(data) {
  return request({
      url: '/server/report/file/' + data,
      method: 'delete',
  })
}

// 预约送货信息
export function getOrderQrInfo(data) {
  return request({
    url: '/server/order/getOrderQrInfo',
    method: 'get',
    params: data
  })
}
// 自动生成
export function getOrderQrInfoAuto(data) {
  return request({
    url: '/server/order/getOrderQrInfoAuto',
    method: 'get',
    params: data
  })
}
//图片更新 
export function checkUpdatePic(data) {
  return request({
    url: '/server/order/checkUpdatePic',
    method: 'get',
    params: data
  })
}
//监听数据汇总
export function merchantOrderStatistics(data) {
  return request({
    url: '/server/order/merchantOrderStatistics',
    method: 'get',
    params: data
  })
}